.cbx {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
}

.cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
}

.cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #9098A9;
    transition: all 0.2s ease;
}

.cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
}

.cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #00c9a7;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
}

.cbx span:last-child {
    padding-left: 8px;
}

.cbx:hover span:first-child {
    border-color: #00c9a7;
}

.inp-cbx-threed:checked+.cbx span:first-child, .inp-cbx-twod:checked+.cbx span:first-child {
    background: #00c9a7;
    border-color: #00c9a7;
    animation: wave 0.4s ease;
}

.inp-cbx-threed:checked+.cbx span:first-child svg, .inp-cbx-twod:checked+.cbx span:first-child svg {
    stroke-dashoffset: 0;
}

.inp-cbx-threed:checked+.cbx span:first-child:before, .inp-cbx-twod:checked+.cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
}

@keyframes wave {
    50% {
        transform: scale(0.9);
    }
}